import styled from 'styled-components'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import HeartFillIcon from 'remixicon-react/HeartFillIcon'
import ArrowLeftRightLineIcon from 'remixicon-react/ArrowLeftRightLineIcon'

import {styledTheme} from '@festi/common/themes'
import {VariantList, VariantDetail} from '@festi/common/api/rest'
import {OutlinedButton} from '@festi/common/components/buttons'
import {useAuth, useComparison} from '@festi/common/contexts'
import {useWishlist} from '@festi/common/utils/wishlists'

const UtilityWrapper = styled.div`
  display: flex;
  gap: 1px;
`

const MiniButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: ${styledTheme.cursor.pointer};

  &:hover {
    background-color: ${styledTheme.palette.ui5Solid};
    border: 1px solid ${styledTheme.palette.ui10Solid};
  }
`

interface ActionButtonsProps {
  variant: VariantList | VariantDetail
  vertical: boolean
  showIcons?: 'both' | 'compareOnly'
}

export default function ProductActionButtons({
  variant,
  vertical,
  showIcons = 'both',
}: ActionButtonsProps) {
  const {user} = useAuth()
  const {isVariantInWishlist, toggleVariantInWishlist} = useWishlist()
  const {comparedSkus, addToComparison, removeFromComparison} = useComparison()

  const inComparison = comparedSkus?.indexOf(variant.sku) !== -1

  return (
    <UtilityWrapper>
      {vertical ? (
        <>
          {showIcons === 'both' && user?.id && (
            <OutlinedButton
              icon={
                isVariantInWishlist(variant.sku) ? HeartFillIcon : HeartLineIcon
              }
              borderColor="ui30Solid"
              onClick={() => toggleVariantInWishlist(variant)}
            >
              Óskalisti
            </OutlinedButton>
          )}
          <OutlinedButton
            icon={ArrowLeftRightLineIcon}
            borderColor={inComparison ? 'blue' : 'ui30Solid'}
            onClick={
              inComparison
                ? () => removeFromComparison(variant.sku)
                : () => addToComparison(variant)
            }
          >
            Bera saman
          </OutlinedButton>
        </>
      ) : (
        <>
          {showIcons === 'both' && user?.id && (
            <MiniButton onClick={() => toggleVariantInWishlist(variant)}>
              {isVariantInWishlist(variant.sku) ? (
                <HeartFillIcon color={styledTheme.palette.lightBlue} />
              ) : (
                <HeartLineIcon color={styledTheme.palette.ui30Solid} />
              )}
            </MiniButton>
          )}
          <MiniButton
            onClick={
              inComparison
                ? () => removeFromComparison(variant.sku)
                : () => addToComparison(variant)
            }
          >
            <ArrowLeftRightLineIcon
              color={
                inComparison
                  ? styledTheme.palette.lightBlue
                  : styledTheme.palette.ui30Solid
              }
            />
          </MiniButton>
        </>
      )}
    </UtilityWrapper>
  )
}
